<template>
    <el-container>
        <el-container>
            <!-- 中间内容 -->
            <el-main>
                <h2 class="depTitle">科室难度系数监测</h2>
                <el-row class="hosrow">
                    <div class="Basicbutto">
                        <el-button type="primary" @click="createDetec">创建检测规则</el-button>
                    </div>
                </el-row>
                <el-table :data="tableCreateDeData" border center>
                    <el-table-column type="index" label="序号" width="55" align="center">
                    </el-table-column>
                    <el-table-column prop="departmentName" label="科室名称" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="监测名称" align="center">
                    </el-table-column>
                    <el-table-column prop="monitorInterval" label="监测区间" align="center">
                    </el-table-column>
                    <el-table-column prop="createDate" label="创建时间" align="center">
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="130">
                        <template style="display: flex" slot-scope="scope">
                            <el-button size="mini" type="primary" @click="WorkMonitData(scope.row)"><i class="el-icon-s-data"></i></el-button>
                            <el-button size="mini" type="danger" @click="WorkMonitDelete(scope.row)"><i class="el-icon-delete-solid"></i></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
                </el-pagination>
            </el-main>
        </el-container>
        <!-- 创建检测规则弹出框 -->
        <el-dialog title="创建检测规则" :visible.sync="createDetectionVisible" width="430px" :before-close="createDetectionVisibleClose">
            <el-form style="width: 95%; margin: 0 auto" :model="AWorkMonitModel" ref="AWorkMonitRef" :rules="AWorkMonitRules" :inline="true" class="WorkMForm">
                <el-form-item label="选择科室：" prop="type">
                    <el-select v-model="AWorkMonitModel.type" placeholder="请选择" style="width: 200px" @change="typeKeshiValueChange(AWorkMonitModel.type)">
                        <el-option v-for="item in typeoptions" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="监测名称：" prop="name">
                    <el-input v-model="AWorkMonitModel.name" placeholder="请填写监测名称"></el-input>
                </el-form-item>
                <el-form-item label="监测区间：" prop="yearzhi">
                    <el-form :model="DateTimeModel" ref="DateTimeRef" :rules="DateTimeRules" :inline="true">
                        <el-form-item prop="beginTime">
                            <el-date-picker v-model="DateTimeModel.beginTime" value-format="yyyy-MM" type="month" :picker-options="pickerOptionsone" placeholder="起始日期" @change="changeOptios">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <span class="centerZhi">至</span>
                        </el-form-item>
                        <el-form-item prop="endTime">
                            <el-date-picker :default-value="startYearTime" v-model="DateTimeModel.endTime" value-format="yyyy-MM" type="month" :picker-options="pickerOptions" placeholder="结束月份" @change="changeOptiosEnd">
                            </el-date-picker>
                        </el-form-item>
                    </el-form>
                </el-form-item>
                <el-form-item>
                    <div class="btnCen">
                        <el-button               v-if="addCreateClick" class="depar-dia"               type="primary"               :loading="addCreateLoading"               @click="addCreateDetecItem"             >创建</el-button>
                                    <el-button               v-else               type="info" class="depar-dia"               :loading="addCreateLoading"             ></el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 折线图 -->
        <el-dialog class="tuEcharts" :visible.sync="createEchartVisible" width="770px">
            <div id="chartColumn" ref="chartColumn" style="width: 100%; margin: 0 auto; height: 400px"></div>
        </el-dialog>
    </el-container>
</template>

<script>
import * as echarts from "echarts";
import _qs from "qs";
export default {
    data() {
        return {
            // 创建检测规则创建按钮
            addCreateLoading: false,
            value1: "",
            pickerOptionsone: {
                disabledDate: (time) => {
                    // console.log(time)
                    return time > Date.now();
                },
            },
            pickerOptions: {
                disabledDate: (time) => {
                    let minYear = this.year;
                    this.startYearTime = minYear;
                    let man = this.DateTimeModel.beginTime;
                    return (
                        //   判断今天日期之后的日期不可选
                        time > Date.now() ||
                        //   判断只能选择第一个框年份的日期
                        time.getTime() > new Date(minYear, 12, 0) ||
                        // 判断第一个框选择的日期之前的不可以选择
                        time.getTime() < new Date(man).setHours(0, 0, 0, 0)
                    );
                },
            },
            typeoptions: [],
            // 设置默认年份
            startYearTime: "",
            chartColumn: null,
            // 图形标弹出框
            createEchartVisible: false,
            // 创建检测规则弹出框
            createDetectionVisible: false,
            // 表格数据
            tableCreateDeData: [],
            year: "", //年份
            DateTimeModel: {
                beginTime: "",
                endTime: "",
            },
            DateTimeRules: {
                beginTime: [{ required: true, message: "请选择开始日期" }],
                endTime: [{ required: true, message: "请选择结束日期" }],
            },
            // 创建检测规则
            AWorkMonitModel: {
                // 监测名称：
                name: "",
                // 监测区间：
                yearzhi: "",
                // 选择科室
                type: "",
            },
            AWorkMonitRules: {
                // 监测名称：
                name: [
                    {
                        required: true,
                        message: "请输入监测名称",
                        trigger: "blur",
                    },
                ],
                // 监测区间：
                yearzhi: [
                    {
                        required: true,
                        message: "请选择监测区间",
                        trigger: "blur",
                    },
                ],
                // 选择科室
                type: [
                    {
                        required: true,
                        message: "请选择科室",
                        trigger: "change",
                    },
                ],
            },
            // 创建检测规则的创建按钮
            addCreateClick: true,
            addCreateLoading: false,

            // 总页数
            total: 1,
            // 每页页数
            pageNum: 1,
            // 每页显示条数
            pageSize: 10,
            //   图表数据
            xList: "",
            yOneList: "",
            yTwoList: "",
            yThreeList: "",
            // 科室id
            departmentId: window.sessionStorage.getItem("departmentId"),
            //   开始月份
            beginMonth: "",
            //   结束月份
            endMonth: "",
            monitorTitle: "",
            // 医院id
            hospitalId: window.sessionStorage.getItem("hospitalId"),
            // 选择科室的科室id
            KeshiDataListvalue: "",
        };
    },
    mounted() {},
    created() {
        this.getDepartmentMonitorList();
    },
    methods: {
        // 获取医院所属科室列表
        async getDepartmentNotPage() {
            let data = {
                hospitalId: this.hospitalId,
            };
            let { data: res } = await this.$axios.getDepartmentNotPage(data);
            // console.log(res)
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: "获取医院所属科室列表失败",
                    type: "error",
                });
            } else if (res.code == 200) {
                this.typeoptions = res.data;
            }
        },
        typeKeshiValueChange(KeshiDataListvalue) {
            this.KeshiDataListvalue = KeshiDataListvalue;
        },
        // 科室监控列表 分页
        async getDepartmentMonitorList() {
            let data = {
                // departmentId: this.departmentId, //科室id

                // 2022-4-12修改
                departmentId: "", //科室id
                pageNum: this.pageNum, //页数
                pageSize: this.pageSize, //每页显示数量
                orderByColumn: "createDate",
                isAsc: "desc",
            };
            let { data: res } = await this.$axios.getDepartmentMonitorList(
                data
            );
            //   console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: "获取科室监控列表失败",
                    type: "error",
                });
            } else if (res.code == 200) {
                this.tableCreateDeData = res.rows;
                this.total = res.total;
            }
        },
        // 创建检测规则
        createDetec() {
            this.createDetectionVisible = true;
            this.getDepartmentNotPage();
        },
        // 点击创建检测规则时候取消正则
        createDetectionVisibleClose() {
            this.addCreateLoading = false;
            this.createDetectionVisible = false;
            this.$refs.AWorkMonitRef.resetFields();

            // 2022-4-12修改 关闭弹出框的时候监测区间内容置空
            this.$refs.DateTimeRef.resetFields();
        },
        // 点击创建检测规则的创建
        addCreateDetecItem() {
            this.AWorkMonitModel.yearzhi =
                this.beginMonth + "/" + this.endMonth + "/" + this.year;
            this.$refs.AWorkMonitRef.validate(async (valid) => {
                if (valid) {
                    this.$refs.DateTimeRef.validate(async (valid) => {
                        if (valid) {
                            this.addCreateClick = false;
                            this.addCreateLoading = true;

                            let data = _qs.stringify({
                                departmentId: this.KeshiDataListvalue, //科室id
                                name: this.AWorkMonitModel.name, //名称
                                year: this.year, //年份
                                beginMonth: this.beginMonth, //开始月份
                                endMonth: this.endMonth, //结束月份
                            });
                            let { data: res } =
                                await this.$axios.editDepartmentMonitor(data);
                            // console.log(res);
                            this.addCreateClick = true;
                            this.addCreateLoading = false;
                            if (res.code == 401) {
                                this.$router.push("/login");
                            } else if (res.code == 200) {
                                this.$message({
                                    message: "添加科室填报监测数据成功",
                                    type: "success",
                                });
                                this.createDetectionVisible = false;
                                this.getDepartmentMonitorList();
                                this.$refs.AWorkMonitRef.resetFields();
                                this.$refs.DateTimeRef.resetFields();
                            } else {
                                this.$message({
                                    message: res.data,
                                    type: "error",
                                });
                            }
                        }
                    });
                }
            });
        },
        // 点击表格的操作的删除
        async WorkMonitDelete(row) {
            const confirmResult = await this.$confirm("确认删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).catch((err) => err);

            if (confirmResult !== "confirm") {
                return this.$message.info("已经取消删除");
            }
            let data = _qs.stringify({
                id: row.id,
            });
            let { data: res } = await this.$axios.removeMonitorChart(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: "删除失败",
                    type: "error",
                });
            } else if (res.code == 200) {
                this.$message({
                    message: "删除成功",
                    type: "success",
                });
                this.getDepartmentMonitorList();
            }
        },
        // 起始年份月份
        changeOptios(val) {
            if (val) {
                this.year = val.split("-")[0];
                const month = val.split("-")[1];
                if (month < 10) {
                    this.beginMonth = month.split("0")[1];
                } else {
                    this.beginMonth = month;
                }
            }
        },
        // 结束时间
        changeOptiosEnd(val) {
            if (val) {
                const month = val.split("-")[1];
                if (month < 10) {
                    this.endMonth = month.split("0")[1];
                } else {
                    this.endMonth = month;
                }
            }
        },
        // 点击表格操作的图形
        WorkMonitData(row) {
            this.monitorTitle = row.monitorInterval + "难度系数监测";
            this.departmentMonitorChart(row.id);
            this.createEchartVisible = true;
        },
        // 科室监控图表接口
        async departmentMonitorChart(row) {
            let data = _qs.stringify({
                id: row, //科室工作量检测监测id
            });
            let { data: res } = await this.$axios.departmentMonitorChart(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: "获取科室监控图表失败",
                    type: "error",
                });
            } else if (res.code == 200) {
                this.xList = res.data.xList;
                this.yOneList = res.data.yOne;
                this.yThreeList = res.data.yThree;
                this.yTwoList = res.data.yTwo;
                this.drawLine();
            }
        },
        drawLine() {
            let aa = this.$refs.chartColumn;
            this.chartColumn = echarts.init(aa);
            this.chartColumn.setOption({
                title: {
                    text: this.monitorTitle,
                    left: "center",
                },
                tooltip: {
                    trigger: "axis",
                },
                toolbox: {
                    show: true,
                    height: "30px",
                    feature: {
                        magicType: { type: ["line", "bar"] },
                        saveAsImage: {},
                    },
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: this.xList,
                    // 给x轴加单位
                    axisLabel: {
                        formatter: "{value} 月",
                    },
                },
                yAxis: {
                    type: "value",
                },
                series: [
                    {
                        name: "常见项目难度系数",
                        type: "line",
                        emphasis: {
                            focus: "series",
                        },
                        data: this.yTwoList,
                        itemStyle: {
                            color: "#b6a2de",
                        },
                    },
                    {
                        name: "风险项目难度系数",
                        type: "line",
                        emphasis: {
                            focus: "series",
                        },
                        data: this.yThreeList,
                        itemStyle: {
                            color: "#5ab1ef",
                        },
                    },
                    {
                        name: "难度系数值（月）",
                        type: "line",
                        emphasis: {
                            focus: "series",
                        },
                        data: this.yOneList,
                        itemStyle: {
                            color: "#ffb980",
                        },
                    },
                ],
            });
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.pageNum = val;
            this.getDepartmentMonitorList();
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .tuEcharts .el-dialog__body {
    padding: 30px 10px 0 0;
}

.centerZhi {
    margin-left: 10px;
    margin-right: 10px;
}

.el-form--inline .el-form-item {
    margin-right: 0px;
}

.hosrow {
    margin: 0 auto;
    margin-bottom: 10px;
    .Basicbutto {
        float: right;
    }
}

.WorkMForm {
    display: flex;
    flex-direction: column;
}

.depTitle {
    display: flex;
    margin: 0 auto;
    margin-bottom: 20px;
}
.el-pagination {
    margin-top: 20px;
}
/deep/ .el-input--prefix .el-input__inner {
    width: 120px;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 120px;
}

.btnCen {
    text-align: center;
      .el-button {
        width: 150px;
    }
}
</style>
